@use '../../scss/vars';

.container {
    width: 100%;
    max-width: vars.$narrowContainerWidth;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
}
