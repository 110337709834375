@use '~@keyliving/component-lib/dist/scss/utils' as *;

.wrapper {
    display: grid;
    width: 100%;
    height: calc(100vh - 6.125rem);
    padding: 3rem;
    color: color('royal', 500);
    place-items: center;
}
