@use '~@keyliving/component-lib/dist/scss/utils' as *;
@use '~@keyliving/component-lib/dist/scss/vars';
@use '~@keyliving/component-lib/dist/scss/mixins';

#auth-modal {
    overflow: hidden;
    border-radius: 1.25rem;
}

#auth-modal.modal--register {
    @include mixins.screen('xl') {
        max-width: 900px;
        padding: 1rem;
    }
}

.title {
    font-family: font-family('serif');
    font-weight: font-weight('semibold');
    text-align: center;
}

.form {
    margin-top: 1.25rem;
}

.submit {
    margin-top: 2.25rem;
}

.secondary-action {
    font-weight: vars.$fw-semibold;
    color: color('royal', 500);
}

.secondary-action:focus {
    outline: none;
}

.form-switcher {
    margin-top: 1.25rem;
    text-align: center;
}

.two-col {
    @include mixins.screen('md') {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
    }

    > div {
        flex-grow: 1;
    }

    > div:nth-of-type(2) {
        margin-top: 1rem;

        @include mixins.screen('md') {
            margin-top: 0;
        }
    }
}
