@use '~@keyliving/component-lib/dist/scss/utils' as *;
@use '~@keyliving/component-lib/dist/scss/mixins' as *;
@use '../../scss/vars';

.wrapper {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: #fff;
}

.container {
    width: 100%;
    max-width: vars.$narrowContainerWidth;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;

    @include screen('xl') {
        padding-right: vars.$containerPadding;
        padding-left: vars.$containerPadding;
    }
}

.toggle {
    position: relative;
    padding: 1.5rem 0.5rem 1.5rem 1rem;

    @include screen('xl') {
        display: none;
    }
}

.toggle:focus {
    outline: none;
}

.toggle__layer {
    width: 30px;
    height: 2px;
    background-color: #000;
    transition: all 200ms;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.toggle__layer + .toggle__layer {
    margin-top: 10px;
}

.toggle--open .toggle__layer-one {
    transform: translate3d(0, 6px, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg)
        skew(0deg, 0deg);
}

.toggle--open .toggle__layer-two {
    transform: translate3d(0, -6px, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg)
        skew(0deg, 0deg);
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main {
    position: static;
    z-index: 101;
    display: flex;
    align-items: center;
}

.brand-logo {
    width: 2rem;
    height: 2rem;

    @include screen('xl') {
        width: 4rem;
        height: 4rem;
    }
}

.home {
    display: block;
    padding-left: 10px;
    margin-right: 1rem;

    @include screen('xl') {
        padding-left: 0;
    }
}

.home:focus {
    outline: none;
}

.home > img {
    width: 100%;
}

.nav-wrapper {
    position: fixed;
    top: vars.$navHeight;
    left: 0;
    z-index: -1;
    display: none;
    width: 100%;
    height: calc(100vh - #{vars.$navHeight});
    padding: 0;
    overflow-y: auto;
    background-color: #fff;

    @include screen('xl') {
        position: relative;
        top: 0;
        display: block;
        height: auto;
        overflow-y: initial;
    }
}

.nav-bar--open .nav-wrapper {
    display: block;
}

.nav-container {
    padding-bottom: 1rem;

    @include screen('xl') {
        padding-bottom: 0;
    }
}

.nav {
    @include screen('xl') {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}

.nav__item {
    display: flex;
    width: 100%;
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    gap: 0.25rem;
    justify-content: space-between;

    @include screen('xl') {
        width: auto;
        padding: 0.25rem 0.5rem;
    }

    .chevron {
        display: none;

        @include screen('xl') {
            display: block;
        }
    }
}

.nav__item:focus {
    outline: none;
}

@include screen('xl') {
    .main-nav-item:focus,
    .main-nav-item:hover {
        color: color('primary');
    }
}

.nav__item--active {
    font-weight: 700;
    color: color('black');
}

.utility {
    position: static;
    z-index: 101;
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0;
    margin-left: auto;
    align-items: center;
    gap: 0.5rem;

    @include screen('xl') {
        padding: 0;
    }
}

.utility__cta {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.5;
    color: color('primary');
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #{color('primary')};
    border-radius: 999px;
    transition-property: all;

    @include screen('xl') {
        font-size: 0.875rem;
    }
}

.utility__cta:hover {
    color: #fff;
    background-color: color('primary');
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: block;

    @include screen('xl') {
        position: absolute;
        z-index: 100;
        display: none;
        width: 250px;
        min-width: 100%;
        overflow: hidden;
        background-color: #fff;
        border: 1px solid #ededed;
        border-radius: 13px;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
    }
}

.dropdown-menu .nav__item {
    display: block;
    padding: 0.5rem 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    color: color('black');
    text-align: left;
    border-top: 1px solid #fff;
    border-radius: 0;
    transition: background-color 0.2s, opacity 0.2s;

    @include screen('xl') {
        padding: 0.75rem 1rem;
    }
}

.dropdown-menu .nav__item:not(:first-child) {
    @include screen('xl') {
        border-top-color: #ededed;
    }
}

.dropdown-menu .nav__item:hover {
    color: color('primary');
    background-color: #f3f3f3;
}

.dropdown-menu--open {
    display: block;
}
