@use '~@keyliving/component-lib/dist/scss/utils' as *;
@use '~@keyliving/component-lib/dist/scss/mixins';

.wrapper {
    display: grid;

    @include mixins.screen('xl') {
        grid-template-columns: 2fr 3fr;
    }
}

.body {
    display: grid;
    padding: 3rem 2rem;
    color: #fff;
    background-color: color('royal', 500);
    place-items: center;

    @include mixins.screen('xl') {
        border-radius: 0.75rem;
    }
}

.form {
    padding: 2rem;

    @include mixins.screen('xl') {
        padding: 3rem 2rem;
    }
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.content) {
    .title {
        font-family: font-family('serif');
        font-weight: font-weight('semibold');
        text-align: left;
    }

    .feature-list {
        padding-left: 0;
        margin-top: 1.5rem;
        font-size: 1rem;
        line-height: 1.25;
        list-style-type: none;

        li {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: flex-start;
        }
    }
}
