@use '@keyliving/component-lib/dist/scss/utils';
@use '../../scss/vars';

.wrapper {
    display: grid;
    place-items: center;
    height: calc(100vh - vars.$navHeight);
    text-align: center;
}

.container {
    width: 100%;
    max-width: 600px;
}

.image {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.title {
    margin-bottom: 0.25em;
    font-family: utils.font-family('serif');
    font-weight: utils.font-weight('semibold');
}

.description {
    font-size: 1rem;
    color: utils.color('grey', 600);
}

.cta {
    margin: 1rem auto 0;
}
