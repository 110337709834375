@use '~@keyliving/component-lib/dist/scss/utils';

.narrow-content {
    width: 100%;
    max-width: 600px;
    padding: 2rem 0;
}

.form-header {
    margin-bottom: 1.5rem;
}

.title {
    margin-bottom: 0.2em;
    font-size: 1.5rem;
    font-weight: 600;
}

.description {
    font-size: 1rem;
    line-height: 1.2;
    color: utils.color('grey', 500);
}
