$narrowContainerWidth: 1366px;
$wideContainerWidth: 1920px;
$containerPadding: 2.5rem;
$mobileContainerPadding: 1.125rem;

/**
* Rough px height of the menu. This isn't hard coded anywhere, just
* what it works out to. This is used for padding and other
* layout calculations to clear the menu.
**/
$navHeight: 3.875rem;
