@use '~@keyliving/component-lib/dist/scss/mixins' as *;
@use '~@keyliving/component-lib/dist/scss/utils';

.item {
    display: flex;
    width: 100%;
    padding: 0.5rem 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.item--active {
    font-weight: 700;
    color: utils.color('black');
}

.account-menu {
    padding-top: 0.875rem;
    margin-top: 1.125rem;
    border-top: 1px solid #{utils.color('grey', 200)};

    @include screen('xl') {
        display: none;
    }
}

.account-menu__heading {
    display: flex;
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.account-menu__heading-icon {
    display: block;
    background-color: utils.color('grey', 200);
    border-radius: 999px;
}

.account-dropdown {
    display: none;
    background-color: #f1f1f1;
    border-radius: 999px;

    @include screen('xl') {
        display: block;
    }
}

.account-dropdown:focus,
.account-dropdown:hover {
    color: #fff;
    background-color: utils.color('primary');
}
