@use '~@keyliving/component-lib/dist/scss/vars' as *;

.wrapper {
    display: grid;
    width: 100%;
    height: calc(100vh - 6.125rem);
    padding: 3rem;
    text-align: center;
    place-items: center;
}

.status {
    font-family: $numbers;
    font-size: 5rem;
    font-weight: $fw-bold;
}

.message {
    font-size: $fs-600;
    line-height: 1.5;
}

.body {
    font-size: 1rem;
    line-height: 1.5;
}
